import StorageService, { NoItemFound } from '@/shared/infrastructure/services/Storage.service'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { Iso8601 } from '@/shared/domain/Clock'
import router from '@/shared/infrastructure/router'
import type {
  RouteLocationNormalized,
  RouteLocation,
  LocationQuery,
  RouteLocationNamedRaw,
} from 'vue-router'
import { jwtDecode } from 'jwt-decode'
import type { FisClientId } from '@/shared/domain/Ids'
import { useAuthStore } from '@/contributor/domain/stores/auth.store'

export default class AuthenticationService {
  public constructor(
    private readonly storageService: StorageService,
    private readonly backofficeGateway: BackofficeGateway,
  ) {}

  public async authenticate(credentials: Credentials): Promise<string | NoResult> {
    const fisLoginResponse = await this.backofficeGateway.login(credentials)
    if (fisLoginResponse instanceof Error) {
      return fisLoginResponse
    }

    this.storageService.set('user', fisLoginResponse.user)
    this.storageService.set('accessToken', fisLoginResponse.tokens.access)

    return fisLoginResponse.tokens.access.token
  }

  public async getAccessToken(): Promise<AccessToken> {
    const authenticationStatus = this.isAuthenticated()
    if (authenticationStatus.loggedIn) {
      return this.storageService.get<AccessToken>('accessToken') as AccessToken
    }
    throw new NavigationInterrupted('token expired or is missing')
  }

  private getContributorId(): FisClientId {
    const accessToken = this.storageService.get<AccessToken>('accessToken') as AccessToken
    const decodedToken = jwtDecode(accessToken.token) as DecodedAccessToken
    return decodedToken.sub.clientId
  }

  public handleBlankPathRedirection(to: RouteLocation): RouteLocation | RouteLocationNamedRaw {
    const authenticationStatus = this.isAuthenticated()

    if (authenticationStatus.loggedIn) {
      if (to.path === '/') {
        return { name: 'home', params: { contributor_id: this.getContributorId() } }
      }
      return to
    }
    return { name: 'login' }
  }

  public async handleLogInRedirection(currentRoute: RouteLocationNormalized): Promise<void> {
    const { params, query } = currentRoute
    const redirectFullPath = query.redirect as string
    const contributorId = params.contributor_id
      ? (params.contributor_id as string)
      : (query.contributorId as string)

    const urlContributorIdAsserted = this.assertUrlContributorIdAndUserContributorId(
      parseInt(contributorId) as FisClientId,
    )
    if (redirectFullPath && urlContributorIdAsserted) {
      await router.replace({ path: redirectFullPath })
    } else {
      await router.replace({
        name: 'home',
        params: {
          contributor_id: this.getContributorId(),
        },
      })
    }
  }

  public async handleLogOutRedirection(to: RouteLocationNormalized): Promise<boolean> {
    const { fullPath, params } = to
    const authenticationStatus = this.isAuthenticated()
    const urlContributorIdAsserted = authenticationStatus.loggedIn
      ? this.assertUrlContributorIdAndUserContributorId(
          parseInt(params.contributor_id as string) as FisClientId,
        )
      : false

    if (urlContributorIdAsserted) {
      if (authenticationStatus.loggedIn) {
        return false
      }

      if (authenticationStatus.reason instanceof NoToken) {
        await this.signOut()
      }

      if (authenticationStatus.reason instanceof TokenExpired) {
        const message = authenticationStatus.reason.message
        await this.signOut({
          type: message,
          redirect: fullPath,
        })
      }

      return true
    }

    await this.signOut({
      redirect: fullPath,
      type: 'unauthorized',
      contributorId: params.contributor_id,
    })
    return true
  }

  private assertUrlContributorIdAndUserContributorId(urlContributorId: FisClientId): boolean {
    return this.getContributorId() === urlContributorId
  }

  private async signOut(query: LocationQuery = {}): Promise<void> {
    this.cleanContributorData()
    await router.replace({ name: 'login', query })
  }

  public isAuthenticated(): AuthenticationStatus {
    const token = this.storageService.get<AccessToken>('accessToken')

    if (token instanceof NoItemFound) {
      return {
        loggedIn: false,
        reason: new NoToken(),
      }
    }

    if (this.tokenExpired(token)) {
      return {
        loggedIn: false,
        reason: new TokenExpired(),
      }
    }

    return {
      loggedIn: true,
      reason: {},
    }
  }

  public cleanContributorData(): void {
    this.storageService.removeAll()
    const authStore = useAuthStore()
    authStore.clear()
  }

  private tokenExpired(token: AccessToken): boolean {
    const expirationAsTimestamp = Date.parse(token.expires)
    const nowAsTimestamp = Date.now()

    const expirationDateNotReached = nowAsTimestamp > expirationAsTimestamp

    return expirationDateNotReached
  }
}

export abstract class NotAuthenticated {}

export class NoToken extends NotAuthenticated {
  public readonly message: string = 'User is not logged or token is missing'
}

export class TokenExpired extends NotAuthenticated {
  public readonly message: string = 'Token expired'
}

class NavigationInterrupted extends Error {
  public constructor(reason: string) {
    super(`Navigation was interrupted, reason: ${reason}`)
  }
}

export type Credentials = {
  username: string
  password: string
}

export type AuthenticatedUser = {
  email: string
  id: number
}

export type AccessToken = {
  token: string
  expires: Iso8601
}

export type DecodedAccessToken = {
  sub: {
    clientId: FisClientId
  }
}

export type AuthenticationStatus = {
  loggedIn: boolean
  reason: NotAuthenticated | NoToken
}
