import type {
  FisClientList,
  FisHttpClient,
  FisLoginResponse,
  FisProject,
  FisZoneList,
  FisPlotList,
  FisLocationList,
  FisMediaList,
  FisMediaClientList,
} from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import { FetchGateway, NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { FisClientId, FisProjectId } from '@/shared/domain/Ids'

export default class BackofficeHttpgateway extends FetchGateway implements BackofficeGateway {
  public baseUrl: string

  public constructor(_baseUrl: string) {
    super()

    this.baseUrl = _baseUrl
  }

  public async login({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<FisLoginResponse | NoResult> {
    const params = new URLSearchParams({ claims: 'clientId' })
    return this.fetch<FisLoginResponse>(`/v1/auth/login?${params}`, {
      method: 'POST',
      body: { email: username, password },
    })
  }

  public async getClients(accessToken: string): Promise<FisClientList | NoResult> {
    return this.fetch<FisClientList>('/v1/clients', { accessToken })
  }

  public async getClient(accessToken: string, clientId: number): Promise<FisHttpClient | NoResult> {
    return this.fetch<FisHttpClient>(
      `/v1/clients/${clientId}?populate=attributions,clientMedias.mediaClient`,
      { accessToken },
    )
  }

  public async getProject(accessToken: string, biomeId: string): Promise<FisProject | NoResult> {
    return await this.fetch<FisProject>(
      `/v1/projects/${biomeId}?populate=tags,sustainableDevelopmentGoals,species`,
      { accessToken },
    )
  }

  public async getParcels(
    accessToken: string,
    idProject: FisProjectId,
  ): Promise<FisPlotList | NoResult> {
    return await this.fetch<FisPlotList>(
      `/v1/parcels?idProject=${idProject}&limit=1000&populate=coordinates&sortBy=idReforestAction:asc`,
      { accessToken },
    )
  }

  public async getLocations(
    accessToken: string,
    idProject: FisProjectId,
  ): Promise<FisLocationList | NoResult> {
    return await this.fetch<FisLocationList>(`/v1/locations?idProject=${idProject}&limit=1000`, {
      accessToken,
    })
  }

  public async getZones(
    accessToken: string,
    idProject: FisProjectId,
  ): Promise<FisZoneList | NoResult> {
    return await this.fetch<FisPlotList>(
      `/v1/zones?idProject=${idProject}&limit=1000&populate=coordinates`,
      { accessToken },
    )
  }

  public async getMedias(
    accessToken: string,
    idProject: FisProjectId,
  ): Promise<FisMediaList | NoResult> {
    return await this.fetch<FisMediaList>(
      `/v1/medias?withUrl=1&idProject=${idProject}&webTags[]=HEADER_MEDIA&webTags[]=WEB_MEDIA&webTags[]=PARTNER_LOGO&webTags[]=CONTEXT_MEDIA&populate=projectMedias`,
      { accessToken },
    )
  }

  public async getMediaClients(
    accessToken: string,
    idClient: FisClientId,
  ): Promise<FisMediaClientList | NoResult> {
    return await this.fetch<FisMediaClientList>(
      `/v1/media-clients?withUrl=1&idClient=${idClient}&webTags[]=LOGO_ORIGINAL&populate=clientMedias`,
      { accessToken },
    )
  }
}
