import { defineStore } from 'pinia'
import type { AccessToken } from '@/authentication/infrastructure/services/Authentication.service'
import {
  NoToken,
  TokenExpired,
} from '@/authentication/infrastructure/services/Authentication.service'
import { useStorage } from '@vueuse/core'

interface IsAuthenticated {
  loggedIn: boolean
  reason: TokenExpired | Record<string, never>
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return useStorage(
      'auth-state',
      {
        accessToken: {
          token: '',
          expires: '',
        },
      },
      sessionStorage,
    )
  },

  getters: {
    isAuthenticated(): IsAuthenticated {
      if (!this.accessToken.token) {
        return {
          loggedIn: false,
          reason: new NoToken(),
        }
      }

      if (this.tokenExpired) {
        return {
          loggedIn: false,
          reason: new TokenExpired(),
        }
      }

      return {
        loggedIn: true,
        reason: {},
      }
    },
    tokenExpired(): boolean {
      const token = this.accessToken
      const expirationAsTimestamp = Date.parse(token.expires)
      const nowAsTimestamp = Date.now()

      return nowAsTimestamp > expirationAsTimestamp
    },
  },

  actions: {
    setToken(token: AccessToken) {
      this.accessToken = token
    },
    clear() {
      this.accessToken = {
        token: '',
        expires: '',
      }
    },
  },
})
