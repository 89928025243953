import { defineStore } from 'pinia'
import { DetailedProject } from '@/project/domain/entities/DetailedProject'
import type { FisProjectId } from '@/shared/domain/Ids'
import type { ReportsList } from '@/project/infrastructure/gateways/Backend.httpgateway'
import type { ChartMeasure } from '@/shared/presentation/presenters/ChartMeasure'
import { Unit } from '@/shared/domain/value-objects/Units'
import type {
  FisHttpClient,
  FisMediaList,
} from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { BeneficiariesApiResults } from '@/project/infrastructure/gateways/Backend.gateway'
import { LatLng, latLng } from 'leaflet'

interface State {
  isLoading: boolean
  currentProjectId: FisProjectId | null
  projects:
    | {
        [x: string]: {
          project: DetailedProject
          beneficiaries: BeneficiariesApiResults | null
        }
      }
    | Record<string, never>

  contributor:
    | {
        client: {
          webName: FisHttpClient['webName']
          name: FisHttpClient['name']
        }
        clientMedias: FisMediaList
      }
    | Record<string, never>
}

class ProjectUnavailable extends Error {
  public constructor() {
    super('projectPage.error.noDataAvailable')
  }
}

export const useProjectStore = defineStore('project', {
  state: (): State => {
    return {
      isLoading: true,
      currentProjectId: null,
      projects: {},
      contributor: {},
    }
  },

  getters: {
    getProject(state): DetailedProject {
      const { projects, currentProjectId } = state
      if (currentProjectId && projects[currentProjectId].project) {
        return DetailedProject.hydrate(projects[currentProjectId].project)
      } else {
        throw new ProjectUnavailable()
      }
    },
    getProjectId(state): FisProjectId | null {
      const { currentProjectId } = state
      if (currentProjectId) {
        return currentProjectId
      }
      return null
    },
    getReports(): ReportsList | null {
      return this.getProject ? this.getProject.reportings : null
    },
    getClientName(): string {
      return this.contributor.client.webName
    },
    getBeneficiaries(state): BeneficiariesApiResults | null {
      const { projects, currentProjectId } = state

      if (currentProjectId) {
        return projects[currentProjectId].beneficiaries
      }

      return null
    },
    reportsListIsFilled(): boolean {
      if (this.getReports) {
        const remoteReportsFilled = this.getReports.remoteReports.length > 0
        const fieldsReportsFilled = this.getReports.fieldsReports.length > 0
        const tropicalReportsFilled = this.getReports.tropicalReports.length > 0

        return remoteReportsFilled || fieldsReportsFilled || tropicalReportsFilled
      }

      return false
    },
    mostRepresentedSpecies(): ChartMeasure[] {
      const { species } = this.getProject
      const sortedSpeciesByRepartition = species.sort((a, b) => b.repartition - a.repartition)

      if (species.length <= 6) {
        return sortedSpeciesByRepartition.map((specie) => ({
          unit: Unit.SPECIES,
          value: specie.repartition,
          label: specie.name,
        }))
      } else {
        const [theFifthMostCommon, theOther] = [
          sortedSpeciesByRepartition.slice(0, 5),
          sortedSpeciesByRepartition.slice(5, sortedSpeciesByRepartition.length),
        ]

        const repartitionOfTheFifthMostCommon: ChartMeasure[] = theFifthMostCommon.map(
          (specie) => ({
            unit: Unit.SPECIES,
            value: specie.repartition,
            label: specie.name,
          }),
        )

        const countRepartitionOfTheOtherSpecies = theOther.map((specie) => specie.repartition).sum()
        const remainingReparationOfTheOtherSpecies: ChartMeasure = {
          unit: Unit.SPECIES,
          value: countRepartitionOfTheOtherSpecies,
          label: 'Others',
        }

        return repartitionOfTheFifthMostCommon.concat(remainingReparationOfTheOtherSpecies)
      }
    },
    polygonsList(): LatLng[][] {
      const project = this.getProject

      if (project) {
        return project.polygons.map((polygon) =>
          polygon.coordinates.map((coordinate) =>
            latLng(coordinate.latitude, coordinate.longitude),
          ),
        )
      }

      return []
    },
    computeOver30NextYears(): number[] {
      const project = this.getProject
      const projectStartingDate = parseInt(project.plantingSeason.split('-')[0])
      const YEAR_0_TO_30 = 31
      return Array(YEAR_0_TO_30)
        .fill(0)
        .map((_, index) => projectStartingDate + index)
    },
  },

  actions: {
    setActiveProject(projectId: FisProjectId) {
      this.currentProjectId = projectId
      this.isLoading = true
    },
    saveProject(detailedProject: DetailedProject) {
      if (this.currentProjectId !== null) {
        this.projects[this.currentProjectId] = {
          project: detailedProject,
          beneficiaries: null,
        }
      }
      this.isLoading = false
    },

    setBeneficiaries(beneficiaries: BeneficiariesApiResults) {
      if (this.currentProjectId !== null && beneficiaries) {
        this.projects[this.currentProjectId].beneficiaries = beneficiaries
      }
    },
  },
})
