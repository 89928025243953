export enum Unit {
  /**
   * 1 ha = 100 mètres par 100 mètres. 100 ha = 1km2.
   */
  HECTARE,
  /**
   * Équivalent en dioxyde de carbone.
   *
   * Masse de dioxyde de carbone qui aurait le même potentiel de réchauffement global (PRG) climatique qu'une quantité
   * donnée d'un autre gaz à effet de serre.
   * L'équivalent dioxyde de carbone pour un gaz est obtenu en multipliant les tonnes de gaz par le PRG associé :
   * millions de tonnes métriques d'équivalents dioxyde de carbone = (millions de tonnes métriques de gaz) * (PRG du gaz)
   */
  TONS_CO2_EQUIVALENT,
  /**
   * The project's impact on biodiversity is calculated using a restoration score ranging from 0 (no biodiversity) to 1
   * (equivalent to the reference ecosystem).
   *
   * This score is converted in haREeq, which corresponds of a unit of reference ecosystem surface that is restored
   * through the project.
   */
  BIODIVERSITY_RESTORATION_HECTARE_EQUIVALENT_REFERENCE_ECOSYSTEM,
  TREES,
  SPECIES,
  TREES_PER_HECTARE,
  SCORE,
}

export type Hectare = number & { _: 'hectare' }
